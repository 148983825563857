<template>
  <li
    class="select-none relative py-2 pl-3 pr-9 transition duration-100 ease-in-out"
    id="listbox-option-0"
    role="option"
    :class="{
      'cursor-pointer ': !isDisabled,
      'text-white bg-indigo-600': isHighlighted && !isDisabled,
      'text-white bg-gray-300': isDisabled,
      'text-gray-900': !isHighlighted && !isDisabled,
    }"
    @click="selectMunicipality()"
  >
    <span
      class="block truncate"
      :class="isSelected ? 'font-semibold' : 'font-normal'"
    >
      {{ municipality?.name }}
    </span>

    <span
      v-if="isSelected"
      class="absolute inset-y-0 right-0 flex items-center pr-4"
      :class="isHighlighted ? 'text-white' : 'text-indigo-600'"
    >
      <!-- Heroicon name: solid/check -->
      <svg
        class="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </span>
  </li>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { MunicipalityDTO } from "@/common/types";

export default defineComponent({
  name: "MunicipalitiesDropdownItem",
  props: {
    municipality: {
      type: Object as PropType<MunicipalityDTO>,
      required: true,
    },
    highLightedMunicipalityUID: {
      type: String,
      required: false,
    },
    selectedMunicipalityUID: {
      type: String,
      required: false,
    },
  },
  emits: {
    selectMunicipality: (municipality: unknown) => !!municipality,
  },
  setup(props, { emit }) {
    const isDisabled = computed(() => props.municipality.active);

    const isHighlighted = computed(
      () => props.highLightedMunicipalityUID === props.municipality.uid
    );
    const isSelected = computed(
      () => props.selectedMunicipalityUID === props.municipality.uid
    );
    const selectMunicipality = () => {
      if (isDisabled.value) return;
      emit("selectMunicipality", props.municipality);
    };
    return { selectMunicipality, isHighlighted, isSelected, isDisabled };
  },
});
</script>

<style scoped></style>
