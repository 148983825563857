
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watchEffect
} from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { MunicipalityDTO } from "@/common/types";
import MunicipalitiesDropdownItem from "@/components/signup/municipalitiesdd/MunicipalitiesDropdownItem.vue";

export default defineComponent({
  name: "MunicipalitiesDropdown",
  components: { MunicipalitiesDropdownItem },
  props: {
    initialValue: {
      type: String,
      required: false
    }
  },
  emits: {
    onMunicipality: (municipalitySlug: unknown) =>
      typeof municipalitySlug === "string"
  },
  setup(props, { emit }) {
    const municipalitiesStore = useMunicipalityStore();
    const municipalitiesState = municipalitiesStore.getState();
    onMounted(async () => await municipalitiesStore.loadMunicipalities(false));

    const municipalities = computed(() => municipalitiesState.municipalities);
    watchEffect(() => {
      const municipality = municipalities.value.find((m) => m.uid === props.initialValue);
      if (!municipality || municipality.active) return;
      selectMunicipality(municipality);
    });
    const dropDownID = ref("dropDown");
    const isOpen = ref(false);
    const highLightedMunicipalityUID = ref<string | null>(null);
    const municipalitySearch = ref<string | null>(null);
    const selectedMunicipalityUID = ref<string | null>(null);
    const filteredMunicipalities = computed(() => {
      if (!municipalitySearch.value) return municipalities.value;
      return municipalities.value.filter((m) =>
        m.name
          .toLowerCase()
          .includes(municipalitySearch.value?.toLowerCase() ?? "")
      );
    });

    const selectMunicipality = (municipality: MunicipalityDTO) => {
      selectedMunicipalityUID.value = municipality.uid;
      municipalitySearch.value = municipality.name;
      isOpen.value = false;
      emit("onMunicipality", municipality.uid);
    };

    const handleMouseClick = (e: MouseEvent) => {
      if (!isOpen.value) return;
      const target = e.target as HTMLDivElement;
      if (!target?.closest(`#${dropDownID.value}`)) isOpen.value = false;
    };

    onMounted(() => window.addEventListener("click", handleMouseClick));

    onBeforeUnmount(() =>
      window.removeEventListener("click", handleMouseClick)
    );

    return {
      isOpen,
      dropDownID,
      highLightedMunicipalityUID,
      selectedMunicipalityUID,
      municipalitySearch,
      filteredMunicipalities,
      selectMunicipality
    };
  }
});
