
import {
  ref,
  defineComponent,
  computed,
  watchEffect,
  onBeforeMount,
} from "vue";
import { useUserStore } from "@/store/UserStore";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/router/types";
import MunicipalitiesDropdown from "@/components/signup/municipalitiesdd/MunicipalitiesDropdown.vue";

export default defineComponent({
  components: { MunicipalitiesDropdown },
  setup() {
    const municipalitySlug = ref<string | null>(null);
    const initialMunicipality = ref<string | null>(null);
    const email = ref<string | null>(null);
    const password = ref<string | null>(null);
    const userStore = useUserStore();
    const userState = userStore.getState();
    const userInfo = computed(() => userState.userInfo);
    const router = useRouter();
    const route = useRoute();
    onBeforeMount(() => {
      const slug = route.query.omavalitsus as string | undefined;
      if (!slug) return;
      initialMunicipality.value = slug;
    });
    watchEffect(() => {
      if (!userInfo.value) return;
      const { municipalitySlug: userMunicipality } = userInfo.value;
      userMunicipality
        ? router.push({
            name: RouteName.MUNICIPALITY_HOME,
            params: { municipalitySlug: userMunicipality },
          })
        : router.push({ name: RouteName.HOME });
    });

    const doSignUp = async () => {
      if (!municipalitySlug.value || !email.value || !password.value) return;
      await userStore.signUpAndLogIn({
        municipalitySlug: municipalitySlug.value,
        email: email.value,
        password: password.value,
      });
    };

    const onMunicipality = (slug: string) => (municipalitySlug.value = slug);

    return {
      email,
      password,
      doSignUp,
      onMunicipality,
      initialMunicipality,
    };
  },
});
