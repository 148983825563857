
import { computed, defineComponent, PropType } from "vue";
import { MunicipalityDTO } from "@/common/types";

export default defineComponent({
  name: "MunicipalitiesDropdownItem",
  props: {
    municipality: {
      type: Object as PropType<MunicipalityDTO>,
      required: true,
    },
    highLightedMunicipalityUID: {
      type: String,
      required: false,
    },
    selectedMunicipalityUID: {
      type: String,
      required: false,
    },
  },
  emits: {
    selectMunicipality: (municipality: unknown) => !!municipality,
  },
  setup(props, { emit }) {
    const isDisabled = computed(() => props.municipality.active);

    const isHighlighted = computed(
      () => props.highLightedMunicipalityUID === props.municipality.uid
    );
    const isSelected = computed(
      () => props.selectedMunicipalityUID === props.municipality.uid
    );
    const selectMunicipality = () => {
      if (isDisabled.value) return;
      emit("selectMunicipality", props.municipality);
    };
    return { selectMunicipality, isHighlighted, isSelected, isDisabled };
  },
});
