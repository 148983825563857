<template>
  <div :id="dropDownID">
    <div class="mt-1 relative">
      <div>
        <label
          for="municipalities-dd-search"
          class="block text-sm font-medium text-gray-700"
        >Omavalitsus</label
        >
        <div class="mt-1">
          <input
            @click="isOpen = true"
            required
            type="text"
            name="municipalities-dd-search"
            id="municipalities-dd-search"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md transition duration-300 ease-in-out"
            autocomplete="off"
            v-model="municipalitySearch"
          />
        </div>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <ul
          v-if="isOpen"
          class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-64 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          role="listbox"
          aria-labelledby="listbox-label"
        >
          <MunicipalitiesDropdownItem
            v-for="municipality of filteredMunicipalities"
            :key="municipality.uid"
            :municipality="municipality"
            :highLightedMunicipalityUID="highLightedMunicipalityUID"
            :selectedMunicipalityUID="selectedMunicipalityUID"
            @mouseenter="highLightedMunicipalityUID = municipality.uid"
            @mouseleave="highLightedMunicipalityUID = null"
            @selectMunicipality="selectMunicipality"
          />
        </ul>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watchEffect
} from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { MunicipalityDTO } from "@/common/types";
import MunicipalitiesDropdownItem from "@/components/signup/municipalitiesdd/MunicipalitiesDropdownItem.vue";

export default defineComponent({
  name: "MunicipalitiesDropdown",
  components: { MunicipalitiesDropdownItem },
  props: {
    initialValue: {
      type: String,
      required: false
    }
  },
  emits: {
    onMunicipality: (municipalitySlug: unknown) =>
      typeof municipalitySlug === "string"
  },
  setup(props, { emit }) {
    const municipalitiesStore = useMunicipalityStore();
    const municipalitiesState = municipalitiesStore.getState();
    onMounted(async () => await municipalitiesStore.loadMunicipalities(false));

    const municipalities = computed(() => municipalitiesState.municipalities);
    watchEffect(() => {
      const municipality = municipalities.value.find((m) => m.uid === props.initialValue);
      if (!municipality || municipality.active) return;
      selectMunicipality(municipality);
    });
    const dropDownID = ref("dropDown");
    const isOpen = ref(false);
    const highLightedMunicipalityUID = ref<string | null>(null);
    const municipalitySearch = ref<string | null>(null);
    const selectedMunicipalityUID = ref<string | null>(null);
    const filteredMunicipalities = computed(() => {
      if (!municipalitySearch.value) return municipalities.value;
      return municipalities.value.filter((m) =>
        m.name
          .toLowerCase()
          .includes(municipalitySearch.value?.toLowerCase() ?? "")
      );
    });

    const selectMunicipality = (municipality: MunicipalityDTO) => {
      selectedMunicipalityUID.value = municipality.uid;
      municipalitySearch.value = municipality.name;
      isOpen.value = false;
      emit("onMunicipality", municipality.uid);
    };

    const handleMouseClick = (e: MouseEvent) => {
      if (!isOpen.value) return;
      const target = e.target as HTMLDivElement;
      if (!target?.closest(`#${dropDownID.value}`)) isOpen.value = false;
    };

    onMounted(() => window.addEventListener("click", handleMouseClick));

    onBeforeUnmount(() =>
      window.removeEventListener("click", handleMouseClick)
    );

    return {
      isOpen,
      dropDownID,
      highLightedMunicipalityUID,
      selectedMunicipalityUID,
      municipalitySearch,
      filteredMunicipalities,
      selectMunicipality
    };
  }
});
</script>

<style scoped></style>
